
import axios from 'axios';


// const URL_PROD = 'http://localhost:3033/ai/'
const URL_PROD = 'https://code-learn.ru/ai/'


export const GetAiRaiting = async () => {
    const url = `${URL_PROD}questions/get/rating`
    try {
        const res = await axios.get(url)
        return res.data
    } catch (error) {
        console.log(error)
        return false

    }
}


// http://localhost:3033/ai/users/get/user/data?user_id=6935066908

export const GetAiAnswer = async (user_id, type, text, question, last_question_id) => {
    const url = `${URL_PROD}questions/ai/answer`
    try {
        const res = await axios.post(url,
            {
                user_id: user_id,
                type: type,
                text: text,
                question: question,
                last_question_id: last_question_id
            }
        )
        return res.data
    } catch (error) {
        console.log(error)
        return false

    }

}

export const userChekPlan = async (user_id, type) => {
    const url = `${URL_PROD}questions/get/usr/plan?user_id=${user_id}&type=${type}`
    try {
        const res = await axios.get(url)
        console.log(res.data)
        return res.data
    } catch (error) {
        console.log(error)
        return error

    }
}

export const userCreatePlan = async (user_id, id) => {
    const url = `${URL_PROD}questions/add/plan?user_id=${user_id}&card_id=${id}`
    console.log(url)
    try {
        const res = await axios.post(url)
        console.log(res)
        return res.data
    } catch (error) {
        console.log(error)
        return false

    }
}

export const GetQuestion = async (user_id, card_id) => {

    const url = `${URL_PROD}questions/get/user?id=${user_id}&type=${card_id}`
    try {
        const res = await axios.get(url)
        return res.data
    } catch (error) {
        console.log(error)
        return error

    }
    // const questions = [
    //     {
    //         text: 'Что такое JavaScript?',
    //         hint: 'Это язык программирования, используемый для создания интерактивных элементов на веб-страницах.'
    //     },
    //     {
    //         text: 'Что такое React?',
    //         hint: 'Это JavaScript-библиотека для создания пользовательских интерфейсов.'
    //     },
    //     {
    //         text: 'Что такое API?',
    //         hint: 'Это интерфейс, который позволяет приложениям взаимодействовать друг с другом.'
    //     },
    //     {
    //         text: 'Что такое JSON?',
    //         hint: 'Это текстовый формат для передачи данных, который легко читается человеком и машинами.'
    //     },
    //     {
    //         text: 'Что такое HTML?',
    //         hint: 'Это стандартный язык разметки для создания веб-страниц.'
    //     }
    // ];

    // // Выбираем случайный вопрос
    // const randomIndex = Math.floor(Math.random() * questions.length);
    // return questions[randomIndex];
};

export const GetCrads = async () => {
    const url = `${URL_PROD}questions/get/cards`
    try {
        const res = await axios.get(url)
        return res.data
    } catch (error) {
        console.log(error)
        return false

    }

    // return [
    //     {
    //         id: '1',
    //         img: 'https://avatars.githubusercontent.com/u/84640980?v=4',
    //         titel: 'Node.js',
    //         description: '40'
    //     },
    //     {
    //         id: '2',
    //         img: 'https://avatars.githubusercontent.com/u/84640980?v=4',
    //         titel: 'JS',
    //         description: '21'
    //     }
    // ]

}


export const getUserData = async (user_id) => {
    const url = `${URL_PROD}users/get/user/data?user_id=${user_id}`
    try {
        const res = await axios.get(url)
        return res.data
    } catch (error) {
        console.log(error)
        return false

    }
}