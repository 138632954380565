import { makeAutoObservable } from "mobx"




class Test_store {
    data = {
        id: null,
        count: 0,
        type: null
    }
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }
    set(value, count, type) {
        this.data.id = value
        this.data.count = count
        this.data.type = type

    }
    reset() {
        this.data.id = null
        this.data.count = 0
        this.data.type = null
    }

}




export default new Test_store()