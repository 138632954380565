import React from "react";
import backgroundImage from "../images/Header.png";
import user_store from "../store/user_store";
import { observer } from "mobx-react-lite";

const WelcomBlock = observer(() => {
  const divStyle = {
    height: "auto",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: "1rem",
    display: "flex",
    padding: "8px",
    borderRadius: "1rem",
  };
  return (
    <>
      <div style={divStyle}>
        <h2 style={{ color: "#0c0c0c" }}>
          Приветствуем, {user_store.data.user_name}!
          <br /> Выбирайте интересные темы и готовьтесь к успеху на следующем
          собеседовании!
        </h2>
      </div>
    </>
  );
});

export default WelcomBlock;
