import React, { useState } from "react";
import { GetAiAnswer, GetQuestion } from "../api/api";
import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
import Messege from "../ui/Messege";
import InfoPanel from "../ui/InfoPanel";
import { HOME_PATH } from "../constants";
import {
  Button,
  Textarea,
  Skeleton,
  Placeholder,
} from "@telegram-apps/telegram-ui";
import { observer } from "mobx-react-lite";
import test_store from "../store/test_store";
import user_store from "../store/user_store";
import { useNavigate } from "react-router-dom";

const Train = observer(() => {
  const navigate = useNavigate();
  const [question, setQuestion] = useState(false);
  const [answer, setAnswer] = useState("");
  const [answerLoud, setAanswerLoud] = useState(false);
  const [result, setResult] = useState(false);
  const [process, setProcess] = useState(false);
  const [processAnswer, setprocessAnswer] = useState(false);
  const [newQuestion, setNewQuestion] = useState(true);
  //Получить вопрос
  const handleaGetQuestion = async () => {
    setProcess(true);
    setAnswer("");
    setAanswerLoud(true);
    const res = await GetQuestion(
      user_store.data.user_id,
      test_store.data.type
    );
    if (res.message == "Request failed with status code 404") {
      handleClick("Информация", "Вопросы закончились");
      setAanswerLoud(false);
      setProcess(false);
    } else {
      setResult(false);
      setAanswerLoud(false);
      setQuestion(res);
      setNewQuestion(false);
      setProcess(false);
    }
  };
  //Проверит ответ
  const handleaiAnswer = async () => {
    if (!answer.length)
      return handleClick("Ошибка", "Ответ не можкт быть пустым");
    setprocessAnswer(true);
    let res = await GetAiAnswer(
      user_store.data.user_id,
      test_store.data.type,
      answer,
      question.question,
      question.id
    );
    if (res === "end_reqwest") {
      handleClick("Информация", "На сегодня запросы кончились");
      user_store.set(0, "reqvests");
      setprocessAnswer(false);
      setNewQuestion(true);
    } else {
      user_store.set(res.requests, "reqvests");
      user_store.set(res.progress.good, "good");
      console.log(res.result);
      setResult(res);
      console.log(result);

      setprocessAnswer(false);
      setNewQuestion(true);
    }
  };
  const showPopup = useShowPopup();
  const handleClick = (titel, text) => {
    showPopup({
      title: titel,
      message: text,
      buttons: [
        {
          type: "ok",
        },
      ],
    }).catch((e) => {
      showPopup({
        title: "error",
        message: e,
      });
    });
  };

  if (test_store.data.id === null) {
    return (
      <>
        <Placeholder
          action={
            <Button onClick={() => navigate(HOME_PATH)} size="l" stretched>
              Выбрать тему
            </Button>
          }
          header="Выбери сначала тему для изучения"
        >
          <img
            style={{ width: "300px" }}
            alt="Telegram sticker"
            className="blt0jZBzpxuR4oDhJc8s"
            src="https://xelene.me/telegram.gif"
          />
        </Placeholder>
      </>
    );
  } else {
    return (
      <>
        <InfoPanel />
        <div className="train_wrapper">
          <div className="train_wrapper_chat">
            {question && (
              <Skeleton visible={answerLoud}>
                <Messege
                  type="left"
                  text={question.question}
                  hint={question.hint}
                />
              </Skeleton>
            )}

            {result && <Messege type="center" text={answer} />}
            {result && (
              <>
                <Messege
                  type="rigth"
                  text={result?.text}
                  result={result.result}
                />
                {result.result}
              </>
            )}
          </div>
          <div className="train_answer_panel">
            {!newQuestion && (
              <Textarea
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                disabled={process}
                header="Ответ"
                placeholder="Ваш ответ"
              />
            )}
            <div className="train_answer_btn">
              {!newQuestion && (
                <Button
                  stretched={true}
                  disabled={processAnswer}
                  loading={process}
                  mode="bezeled"
                  size="m"
                  onClick={handleaGetQuestion}
                >
                  Пропустить
                </Button>
              )}

              {newQuestion ? (
                <Button
                  stretched={true}
                  loading={process}
                  size="m"
                  onClick={handleaGetQuestion}
                >
                  Получить вопрос
                </Button>
              ) : (
                <Button
                  stretched={true}
                  loading={processAnswer}
                  size="m"
                  onClick={handleaiAnswer}
                >
                  Проверить
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
});

export default Train;
