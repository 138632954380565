import React from "react";
import { observer } from "mobx-react-lite";
import {
  Image,
  Text,
  Badge,
  Cell,
  Switch,
  Button,
} from "@telegram-apps/telegram-ui";
import User_store from "../store/user_store";
import Rating from "./Rating";

const Profile = observer(() => {
  const futureDateString = (timestamp) => {
    let date = new Date(timestamp);
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Месяцы начинаются с 0
    let day = date.getDate().toString().padStart(2, "0");

    // Преобразование в строку в формате YYYY-MM-DD HH:MM:SS
    return `${day}-${month}-${year}`;
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const posts = await getUserData();
  //       setData(posts);
  //       setLoading(false);
  //     } catch (error) {
  //       setError(error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div>
        <h3>Профиль</h3>
        <Image size={48} src={User_store.data.photo_url} />
        <br />
        <Text weight="1">{User_store.data.user_name}</Text>
        <br />
        <Text weight="1">{User_store.data.user_id}</Text>
        <br />
        <Text weight="2">
          Запросов
          <Badge mode="primary" type="number">
            {User_store.data.reqvests}
          </Badge>
        </Text>
        <br />
        <Text weight="2">
          Действуют до
          <Badge mode="primary" type="number">
            {futureDateString(Number(User_store.data.subscription_date))}
          </Badge>
        </Text>
        <br />
        <br />
        <Button
          Component="a"
          href="https://pay.cloudtips.ru/p/98d19db2"
          mode="filled"
          size="m"
          target="_blank"
        >
          Поддержать проект деньгой 💚
        </Button>
      </div>

      {/* <Cell after={<Switch defaultChecked />} multiline>
        Получать уведомления о добавленных запросах
      </Cell>
      <Cell after={<Switch defaultChecked />} multiline>
        Напоминание о тренировке
      </Cell> */}
    </>
  );
});

export default Profile;
