import React from "react";
import WelcomBlock from "../ui/Welcome_block";
import CardTrain from "../ui/CardTrain";
import Cards_store from "../store/cards_store";
import { observer } from "mobx-react-lite";
import { Spinner } from "@telegram-apps/telegram-ui";

const Home = observer(() => {
  const cards = Cards_store.data.map((elem) => {
    return (
      <CardTrain
        id={elem.id}
        img={elem.img_url}
        titel={elem.type}
        description={elem.questions.length}
      />
    );
  });
  return (
    <>
      <div style={{ marginBottom: "3rem" }}>
        <WelcomBlock></WelcomBlock>
        {Cards_store.data.length === 0 ? <Spinner size="m" /> : cards}
        {/* {cards} */}
      </div>
    </>
  );
});

export default Home;
