import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HOME_PATH, TRAIN_PATH, PROFILE_PATH, RATING_PATH } from "../constants";
import {
  Tabbar,
  Modal,
  Button,
  Placeholder,
  Cell,
} from "@telegram-apps/telegram-ui";
import { useNavigate } from "react-router-dom";

const MenuNav = () => {
  const location = useLocation();
  const [path, setPath] = useState(HOME_PATH);

  useEffect(() => {
    // Это будет выполняться каждый раз при изменении URL
    console.log(
      "URL изменился:",
      location.pathname + location.search + location.hash
    );
    setPath(location.pathname);
    // Вы можете выполнять здесь любые действия, которые нужны при изменении URL,
    // например, отправку аналитики, изменение состояния и т.д.
  }, [location]);

  const navigate = useNavigate();
  const goToContact = (path) => {
    navigate(path);
  };
  return (
    <>
      {/* <Modal trigger={<Button size="m">меню</Button>}>
        <Placeholder header="Меню"></Placeholder>

        <Cell>Главная</Cell>
        <Cell>Тренировка</Cell>
      </Modal> */}
      <div className="menu_wrapper">
        <Tabbar.Item
          onClick={() => goToContact(HOME_PATH)}
          selected={path === HOME_PATH}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
              stroke={
                path === HOME_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Tabbar.Item>
        <Tabbar.Item
          onClick={() => goToContact(TRAIN_PATH)}
          selected={path === TRAIN_PATH}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M6 10V16C6 18.2091 7.79086 20 10 20H14C16.2091 20 18 18.2091 18 16V10"
              stroke={
                path === TRAIN_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
            />
            <path
              d="M12.36 13L3 8L12.36 3L21 8L12.36 13Z"
              stroke={
                path === TRAIN_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M3 8V16"
              stroke={
                path === TRAIN_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Tabbar.Item>
        <Tabbar.Item
          onClick={() => goToContact(PROFILE_PATH)}
          selected={path === PROFILE_PATH}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9848 15.3462C8.11719 15.3462 4.81433 15.931 4.81433 18.2729C4.81433 20.6148 8.09624 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8734 15.3462 11.9848 15.3462Z"
              stroke={
                path === PROFILE_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94779 16.58 7.40969C16.58 4.8716 14.5229 2.81445 11.9848 2.81445C9.44667 2.81445 7.38858 4.8716 7.38858 7.40969C7.38001 9.93922 9.42382 11.9973 11.9524 12.0059H11.9848Z"
              stroke={
                path === PROFILE_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Tabbar.Item>
        <Tabbar.Item
          onClick={() => goToContact(RATING_PATH)}
          selected={path === RATING_PATH}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M15.8624 17.5L18.4647 19.0478C19.217 19.4389 20.0969 18.8082 19.9537 17.9815L19.3723 14.6397C19.315 14.3111 19.4252 13.9765 19.6673 13.7437L22.1308 11.3766C22.7399 10.7921 22.405 9.77203 21.5633 9.65051L18.1566 9.16015C17.8226 9.11223 17.5345 8.90513 17.3852 8.60646L15.8624 5.56417C15.4858 4.81194 14.3986 4.81194 14.022 5.56417L13 8"
              stroke={
                path === RATING_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.1043 4.67701L12.9317 8.32776C13.1108 8.68616 13.4565 8.93467 13.8573 8.99218L17.9453 9.58062C18.9554 9.72644 19.3573 10.9505 18.6263 11.6519L15.6702 14.4924C15.3797 14.7718 15.2474 15.1733 15.3162 15.5676L16.0138 19.5778C16.1856 20.5698 15.1298 21.3267 14.227 20.8574L10.5732 18.9627C10.215 18.7768 9.78602 18.7768 9.42679 18.9627L5.773 20.8574C4.87023 21.3267 3.81439 20.5698 3.98724 19.5778L4.68385 15.5676C4.75257 15.1733 4.62033 14.7718 4.32982 14.4924L1.37368 11.6519C0.642715 10.9505 1.04464 9.72644 2.05466 9.58062L6.14265 8.99218C6.54354 8.93467 6.89028 8.68616 7.06937 8.32776L8.89574 4.67701C9.34765 3.77433 10.6523 3.77433 11.1043 4.67701Z"
              stroke={
                path === RATING_PATH
                  ? "var(--tgui--button_color)"
                  : "var(--tgui--white)"
              }
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Tabbar.Item>
      </div>
    </>
  );
};
export default MenuNav;

// --tgui--white: #fff;
// --tgui--black
