import { makeAutoObservable } from "mobx"


class User_store {
    data = {
        user_id: '',
        user_name: 'Путник',
        photo_url: 'https://avatars.githubusercontent.com/u/84640980?v=4',
        reqvests: 0,
        good: 0,
        subscription_date: 0
    }
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }
    set(value, key) {
        let newstate = this.data
        newstate[key] = value
        return newstate
    }
}

export default new User_store()