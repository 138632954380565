import React, { useState } from "react";
import { Button, Subheadline, Image } from "@telegram-apps/telegram-ui";
import { useNavigate } from "react-router-dom";
import test_store from "../store/test_store";
import user_store from "../store/user_store";

import { observer } from "mobx-react-lite";
import { userChekPlan, userCreatePlan } from "../api/api";

const CardTrain = observer(({ id, img, titel, description }) => {
  const navigate = useNavigate();
  const [process, setProcess] = useState(false);

  const goToContact = (path) => {
    navigate(path);
  };

  const createPlan = async () => {
    setProcess(true);
    let res = await userChekPlan(user_store.data.user_id, titel);
    console.log(res);

    if (res.status === true) {
      test_store.set(id, description, titel);
      user_store.set(res.correct, "good");
      setProcess(false);
      goToContact("/train");
      console.log("Да");
    } else {
      let new_plan = await userCreatePlan(user_store.data.user_id, id);
      if (new_plan) {
        test_store.set(id, description, titel);
        setProcess(false);
        goToContact("/train");
        console.log("Нет");
        console.log(user_store.data.user_id, id);
      }
      setProcess(false);
    }
    //   test_store.set(id, description, titel);
    //   setProcess(false);
    //   goToContact("/train");
    // } else {
    //   alert("Добавляем");

    //   setProcess(true);
    //   let create_res = await userCreatePlan(user_store.data.user_id, id);
    //   if (create_res) {
    //     test_store.set(id, description, titel);
    //     setProcess(false);
    //     goToContact("/train");
    //   }
    // }
  };

  return (
    <>
      <div className="row_box">
        <div className="row_box_children">
          <Image size={48} src={img} />
          <div>
            <Subheadline level="1" weight="1">
              {titel}
            </Subheadline>
            <Subheadline level="1" weight="3">
              {description} вопросов
            </Subheadline>
          </div>
        </div>
        <Button
          loading={process}
          onClick={() => {
            createPlan();
          }}
        >
          Перейти
        </Button>
      </div>
    </>
  );
});
export default CardTrain;
