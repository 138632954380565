import React, { useEffect } from "react";
import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { observer } from "mobx-react-lite";
import {
  Button,
  Blockquote,
  Placeholder,
  Modal,
} from "@telegram-apps/telegram-ui";
import Markdown from "react-markdown";
const left = "left";
const rigth = "rigth";
const center = "center";

const Messege = observer(({ type, text, hint, result }) => {
  const showPopup = useShowPopup();

  const handleClick = (titel, text) => {
    showPopup({
      title: titel,
      message: text,
      buttons: [
        {
          type: "ok",
        },
      ],
    }).catch((e) => {
      showPopup({
        title: "error",
        message: e,
      });
    });
  };

  if (type === left) {
    return (
      <>
        <Blockquote>
          <div style={{ marginBottom: "1rem" }}>
            <Markdown>{text}</Markdown>
          </div>

          <Modal
            trigger={
              <Button mode="outline" size="m">
                Подсказка
              </Button>
            }
          >
            <Placeholder style={{ paddingBottom: "5rem" }}>
              <Markdown>{hint}</Markdown>
            </Placeholder>
          </Modal>
        </Blockquote>
      </>
    );
  }

  if (type === rigth) {
    return (
      <>
        <div
          style={{ background: result ? "#b8ffb86e" : "#f5060f6e" }}
          className="messege_r"
        >
          <Markdown>{text}</Markdown>
        </div>
      </>
    );
  }
  if (type === center) {
    return (
      <>
        <div className="messege_c">
          твой ответ<br></br>
          <Markdown>{text}</Markdown>
        </div>
      </>
    );
  }
});

export default Messege;
