import { makeAutoObservable } from "mobx"




class Cards_store {
    data = []
    constructor() {
        makeAutoObservable(this, {}, { deep: true })
    }
    set(value) {
        this.data = value
    }
}




export default new Cards_store()
