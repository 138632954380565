import React, { useState } from "react";
import { useShowPopup } from "@vkruglikov/react-telegram-web-app";
import { Avatar, Badge } from "@telegram-apps/telegram-ui";
import test_store from "../store/test_store";
import user_store from "../store/user_store";
import { observer } from "mobx-react-lite";

const InfoPanel = observer(({ id, img, titel, description }) => {
  // Соотношение правильно отвеченных вопросов ко всем вопросам
  const showPopup = useShowPopup();
  const handleClickShowPopup = (titel, text) => {
    showPopup({
      title: titel,
      message: text,
      buttons: [
        {
          type: "ok",
        },
      ],
    }).catch((e) => {
      showPopup({
        title: "error",
        message: e,
      });
    });
  };

  return (
    <>
      <div className="info_panel">
        <Avatar size={20} src={user_store.data.photo_url} />
        <Badge
          mode="secondary"
          type="number"
          onClick={() => {
            handleClickShowPopup(
              "Инфо",
              "Соотношение правильно отвеченных вопросов ко всем вопросам"
            );
          }}
        >
          {user_store.data.good}/{test_store.data.count}
        </Badge>
        <Badge
          mode="primary"
          type="number"
          onClick={() => {
            handleClickShowPopup(
              "Инфо",
              "Количество ответов на сегодня обновляется раз в день."
            );
          }}
        >
          {user_store.data.reqvests}
        </Badge>
        <Badge mode="gray" type="number">
          {test_store.data.type}
        </Badge>
      </div>
    </>
  );
});
export default InfoPanel;
