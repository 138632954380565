import './App.css';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot, Placeholder, Button } from "@telegram-apps/telegram-ui";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import MenuNav from './ui/MenuNav'
import Profile from './pages/Profile'
import Home from './pages/Home';
import Train from './pages/Train'
import Rating from './pages/Rating'
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite'
import user_store from './store/user_store'
import { HOME_PATH, TRAIN_PATH, PROFILE_PATH, RATING_PATH } from './constants'
import Cards_store from './store/cards_store'
import { GetCrads, getUserData } from './api/api'


const App = observer(() => {
    const [initDataUnsafe, setinitDataUnsafe] = useState(false);
    const [test, setTest] = useState(false);


    const fetchUserData = async (user_id) => {
        try {
            const user = await getUserData(user_id);
            if (user) {
                console.log(user.user_id)
                user_store.set(user.user_name, 'user_name')
                user_store.set(String(user.user_id), 'user_id')
                user_store.set(user.user_image, 'photo_url')
                user_store.set(user.reqvests, 'reqvests')
                console.log(user.subscription_date)
                user_store.set(user.subscription_date, 'subscription_date')



            }
        } catch (error) {
            console.log(error)

        }
    };
    const fetchData = async () => {
        try {
            const cards = await GetCrads();
            Cards_store.set(cards)
        } catch (error) {
        }
    };

    useEffect(() => {
        // Проверяем, что объект Telegram WebApp доступен

        if (test) {
            fetchUserData(String('6182050210'))
            console.log(String('6182050210'))
            setinitDataUnsafe(true)
            fetchData();
        }




        if (window.Telegram?.WebApp) {
            const tg = window.Telegram.WebApp;

            // Подготавливаем Web App
            tg.ready();

            // Расширяем на весь экран
            tg.expand();

            const tg_user_id = tg?.initDataUnsafe?.user?.id || null;
            fetchUserData(String(tg_user_id))
            console.log(String(tg_user_id))
            setinitDataUnsafe(true)
            fetchData();
            // user_store.set(tg_username, 'user_name',)
            // user_store.set('1', 'user_id',)
            // user_store.set(photo_url, 'photo_url',)



        } else {
            console.error("Telegram Web App SDK не загружен.");
        }
    }, []);

    if (initDataUnsafe) {
        return (
            <AppRoot>

                <Router>
                    <MenuNav />
                    <Routes>
                        <Route path={HOME_PATH} element={<Home />} />
                        <Route path={TRAIN_PATH} element={<Train />} />
                        <Route path={PROFILE_PATH} element={<Profile />} />
                        <Route path={RATING_PATH} element={<Rating />} />


                    </Routes>
                </Router>

            </AppRoot >
        );
    } else {
        return (
            <AppRoot>
                <>
                    <Placeholder
                        action={<Button Component="a"
                            href="https://t.me/web_app_test_v1_bot"
                            mode="filled"
                            size="m"
                            target="_blank">Перейти</Button>}
                        description="Сервис доступен только в Телеграм"
                        header="Ошибочка"
                    >

                    </Placeholder>
                </>
            </AppRoot>
        )
    }


})

export default App;
